import React ,{ useState} from 'react';
import Navbar from '../Component/Navbar_GPTPage/Navbar_GPTPage';
import VoiceRecorder from '../Component/VoiceRecorder/VoiceRecorder';
import Footer from '../Component/Footer/Footer';
import '../../src/styles/tailwind.css';
import Sidebar from '../Component/Sidebar/Sidebar';
const ArdraGPTPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  return (
    <>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Navbar isSidebarOpen={isSidebarOpen} />
      <VoiceRecorder isSidebarOpen={isSidebarOpen} />
      <Footer isSidebarOpen={isSidebarOpen}/>
    </>
  );
};
export default ArdraGPTPage;