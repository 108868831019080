import React from 'react';
import './Footer.css';
import footerLogo from '../../Assist/footerLogo.png';
import { Link } from 'react-router-dom';

const Footer = ({ isSidebarOpen }) => {
  return (
    <footer className={`footer ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="footer-left">
        <Link to="/ardraGPTPage">
          <img src={footerLogo} alt="Ardra AI Logo" className="footer-logo" />
        </Link>
        <div className="footer-divider"></div>
        <div className="footer-about">
          <a href="https://ardra.ai/" target="_blank" rel="noopener noreferrer" className="footer-text">About Us</a>
        </div>
      </div>

      <div className="footer-center">
        <p className="footer-center-text">
          © 2024 Ardra AI Inc | USA | India | All Rights Reserved |
        </p>
        <div className="footer-links">
          <span> <a href="/terms-and-conditions" className="footer-link">Terms & Conditions</a></span>
          <span className="footer-separator">|</span>
          <span><a href="/privacy-policy" className="footer-link">Privacy Policy</a></span>
        </div>
      </div>

      <a href="https://calendly.com/pranjal-2diz/ardra-ai-socials" target="_blank" rel="noopener noreferrer" className="footer-demo-link">Book a Demo</a>
    </footer>
  );
};

export default Footer;
