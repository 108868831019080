import React, { useState } from "react";
import { FaArrowDown, FaEye, FaEyeSlash, FaCheckCircle } from "react-icons/fa";
import logo from "../../Assist/footerLogo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "./GoogleSigninButton";
import { useUser } from "../context/UserContext";
import "./SigninSignup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntroVideo from "../../Assist/pipeline-intro-video.mp4";
import { Helmet } from "react-helmet";


const SigninSignup = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [isSignup, setIsSignup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUserId, setToken } = useUser(); // Get setUserId & setToken from context
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  // Additional SEO Meta Tags
  const seoTitle = isSignup ? "Signup for ardraGPT" : "Login to ardraGPT";
  const seoDescription = isSignup
    ? "Create a new account on ardraGPT and access AI-driven solutions for energy management."
    : "Login to your ardraGPT account to manage and optimize your energy solutions efficiently.";
  const seoKeywords = "ardraGPT, energy management, AI solutions, signup, login";

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const toggleForm = () => {
    setIsSignup(!isSignup);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
    }); // Reset form on toggle
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidations(validations);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, password: value });
    validatePassword(value, formData.confirm_password);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, confirm_password: value });
    validatePassword(formData.password, value);
  };

  const scrollToFeatures = () => {
    const featuresSection = document.getElementById("features-section");
    featuresSection.scrollIntoView({ behavior: "smooth" });
  };

  const registerUser = async (e) => {
    e.preventDefault();
    try {
      if (
        !formData.first_name ||
        !formData.last_name ||
        !formData.email ||
        !formData.password ||
        !formData.confirm_password
      ) {
        toast.error("Please fill in all fields!");
        return;
      }
      // Check if passwords match
      if (formData.password !== formData.confirm_password) {
        toast.error("Password and Confirm Password must match.");
        return;
      }

      const response = await axios.post(`${API_URL}/register`, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirm_password,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Signup successful! Please log in.", {
          autoClose: 2000,
        });
        setIsSignup(false);
        localStorage.setItem("isSignup", JSON.stringify(true));
      } else {
        toast.error(response.data.message || "Signup failed.");
      }

      console.log("Registration Response:", response.data);
    } catch (error) {
      toast.error("Error registering user. Please try again.");
      console.error("Error registering user:", error);
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();

    const { email, password } = formData; // Ensure formData has email and password

    if (!email || !password) {
      toast.error("Please fill in all fields!");
      return;
    }
    const requestBody = JSON.stringify({ email, password });
    console.log("Request body:", requestBody);

    try {
      const response = await axios.post(`${API_URL}/login`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        const { token, user } = response.data; // Accessing token and user from the response
        localStorage.setItem("token", token); // Store token in local storage
        localStorage.setItem("userId", user.id); // Store user ID in local storage
        setUserId(user.id); // Set userId in context
        setToken(token); // Set token in context
        const isSignup = localStorage.getItem("isSignup") && JSON.parse(localStorage.getItem("isSignup"));
        if (isSignup) {
          toast.success("Welcome to ardraGPT! " + user.first_name, { autoClose: 2000 });
          localStorage.removeItem("isSignup");
        } else {
          toast.success("Welcome back! " + user.first_name, { autoClose: 2000 });
        }

        console.log("User ID:", user.id);

        navigate("/ardraGPTPage"); // Navigate after login success
      } else {
        toast.error(response.data.message || "Login failed.");
      }
    } catch (error) {
      const statusCode = error.response?.status;
      const errorMessage = error.response?.data?.message;

      if (statusCode === 401) {
        // Unauthorized: incorrect email or password
        toast.error("Invalid email or password. Please try again.");
      } else {
        // Generic error message for other cases
        toast.error(errorMessage || "Error logging in. Please try again.");
      }
    }
  };

  return (
    <div id="signinsignup_section" className="relative flex overflow-hidden">
      {/* seo on login */}

      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta name="author" content="Ardra AI" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={`${window.location.origin}/signin-signup`} />
      </Helmet>




      {/* Left Side - Forms */}
      <div className="w-full md:w-1/2 bg-gradient-to-r from-gray-800 to-gray-900 text-white px-8 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center justify-center mb-4 mt-6">
          <img src={logo} alt="Logo" className="w-70 h-20" />
          <h2 className="text-xl mt-[-20px]">ardraGPT</h2>
        </div>

        {/* Conditional Form Rendering */}
        {isSignup ? (
          <div className="space-y-4 w-full max-w-md">
            {/* Scrollable Form */}
            <div className=" overflow-y-visible p-4 ">
              <form
                className="space-y-2"
                onSubmit={registerUser}
                id="register_form"
              >
                <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={(e) =>
                      setFormData({ ...formData, first_name: e.target.value })
                    }
                    className="w-full md:w-1/2 py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={(e) =>
                      setFormData({ ...formData, last_name: e.target.value })
                    }
                    className="w-full md:w-1/2 py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  />
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="w-full py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                />
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    onFocus={() => setShowValidation(true)}
                    onBlur={() => {
                      const allValid = Object.values(passwordValidations).every(Boolean);
                      if (allValid || !formData.password) setShowValidation(false);
                    }}
                    className="w-full py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  {/* Password Validation Pop-up */}
                  {showValidation && (
                    <div className="absolute -top-[220px]  left-4 w-50% bg-gray-800 text-white p-4 rounded-lg shadow-lg" id={"validation"}>
                      <p className="font-bold mb-2">
                        Password must meet the following requirements:
                      </p>
                      <div className="text-sm space-y-2">
                        <div
                          className={`flex items-center ${passwordValidations.length ? "text-green-500" : "text-gray-500"
                            }`}
                        >
                          {passwordValidations.length && <FaCheckCircle className="mr-2" />}
                          At least 8 characters long
                        </div>
                        <div
                          className={`flex items-center ${passwordValidations.uppercase ? "text-green-500" : "text-gray-500"
                            }`}
                        >
                          {passwordValidations.uppercase && <FaCheckCircle className="mr-2" />}
                          Contains at least one uppercase letter
                        </div>
                        <div
                          className={`flex items-center ${passwordValidations.lowercase ? "text-green-500" : "text-gray-500"
                            }`}
                        >
                          {passwordValidations.lowercase && <FaCheckCircle className="mr-2" />}
                          Contains at least one lowercase letter
                        </div>
                        <div
                          className={`flex items-center ${passwordValidations.number ? "text-green-500" : "text-gray-500"
                            }`}
                        >
                          {passwordValidations.number && <FaCheckCircle className="mr-2" />}
                          Contains at least one number
                        </div>
                        <div
                          className={`flex items-center ${passwordValidations.specialChar ? "text-green-500" : "text-gray-500"
                            }`}
                        >
                          {passwordValidations.specialChar && <FaCheckCircle className="mr-2" />}
                          Contains at least one special character
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                {/* Password Validation Checklist */}
                {/* <div className="text-sm text-white space-y-2">
                  <div className="flex items-center">
                    <span
                      className={
                        passwordValidations.length
                          ? "text-green-500 flex items-center"
                          : "text-gray-500 flex items-center"
                      }
                    >
                      {passwordValidations.length && (
                        <FaCheckCircle className="text-green-500 ml-5 mr-[-5px]" />
                      )}
                      <span className="ml-5">At least 8 characters long.</span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span
                      className={
                        passwordValidations.uppercase
                          ? "text-green-500 flex items-center"
                          : "text-gray-500 flex items-center"
                      }
                    >
                      {passwordValidations.uppercase && (
                        <FaCheckCircle className="text-green-500 ml-5 mr-[-5px]" />
                      )}
                      <span className="ml-5">
                        Contains at least one uppercase letter.
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span
                      className={
                        passwordValidations.lowercase
                          ? "text-green-500 flex items-center"
                          : "text-gray-500 flex items-center"
                      }
                    >
                      {passwordValidations.lowercase && (
                        <FaCheckCircle className="text-green-500 ml-5 mr-[-5px]" />
                      )}
                      <span className="ml-5">
                        Contains at least one lowercase letter.
                      </span>
                    </span>
                  </div>

                  <div className="flex items-center">
                    <span
                      className={
                        passwordValidations.number
                          ? "text-green-500 flex items-center"
                          : "text-gray-500 flex items-center"
                      }
                    >
                      {passwordValidations.number && (
                        <FaCheckCircle className="text-green-500 ml-5 mr-[-5px]" />
                      )}
                      <span className="ml-5">
                        Contains at least one number.
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span
                      className={
                        passwordValidations.specialChar
                          ? "text-green-500 flex items-center"
                          : "text-gray-500 flex items-center"
                      }
                    >
                      {passwordValidations.specialChar && (
                        <FaCheckCircle className="text-green-500 ml-5 mr-[-5px]" />
                      )}
                      <span className="ml-5">
                        Contains at least one special character.
                      </span>
                    </span>
                  </div>
                </div> */}

                {/* new */}












                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={formData.confirm_password}
                    onChange={handleConfirmPasswordChange}
                    className="w-full py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                <button className="w-full py-3 bg-custom-gradient text-white rounded-lg hover:shadow-lg transition duration-300">
                  {/* gradient-to-r from-cyan-400 to-blue-500  */}
                  Sign Up
                </button>
              </form>
            </div>

            {/* Non-scrollable Elements */}
            <p className="text-gray-300 mt-4 text-center">
              Already have an account?{" "}
              <span
                className="text-custom-blue cursor-pointer"
                onClick={toggleForm}
              >
                Sign In
              </span>
            </p>
          </div>
        ) : (
          <div className="space-y-6 w-full max-w-md">
            <form className="space-y-4" id="loginForm" onSubmit={loginUser}>
              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="w-full py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
              />
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  className="w-full py-3 px-4 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-custom-blue"
                // cyan-400
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-3 text-gray-400 cursor-pointer"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <button className="w-full py-3 bg-custom-gradient text-white rounded-lg hover:shadow-lg transition duration-300">
                Sign In
              </button>

            </form>
            <p className="text-gray-300 text-center" id="or">or</p>
            <GoogleLoginButton />
            {/* <GoogleLoginButton setUserId={setUserId} setToken={setToken} /> */}

            <p className="text-gray-300 text-center">
              Don’t have an account?{" "}
              <span
                className="text-custom-blue cursor-pointer"
                onClick={toggleForm}
              >
                Sign Up
              </span>
            </p>
          </div>
        )}

        {/* Explore Button */}
        <div className="mt-4 text-center">
          <button
            onClick={scrollToFeatures}
            className="text-white text-lg bg-transparent border-2 border-custom-blue rounded-full py-2 px-6 hover:bg-custom-gradient hover:text-gray-900 transition duration-300 mb-3"
          >
            Explore Features{" "}
            <i class="fa-solid fa-arrow-down fa-bounce inline ml-2"></i>
          </button>
        </div>
      </div>

      {/* Right Side - Background Image */}
      <div
        className="hidden md:flex md:w-1/2 justify-center items-center"
        id="pipeline-intro"
      >
        <video
          id="pipeline-intro-video"
          width="75%"
          className="object-cover"
          src={IntroVideo}
          style={{ border: "none", borderRadius: "15px" }}
          autoPlay
          loop
          muted
        ></video>
      </div>
    </div>
  );
};

export default SigninSignup;
