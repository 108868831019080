import React, { useState, useEffect } from "react";
import "./Navbar_GPTPage.css";
import logo from "../../Assist/navbarLogo.png";
import ModalForm from "../GenerativeAIExpertPopup/GenerativeAIExpertPopup";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'

const statements = ["Voice-Powered Data Visualization", "AI-Enabled Graphs"];

const Navbar = ({ isSidebarOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [navbarTransparent, setNavbarTransparent] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const [statementIndex, setStatementIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setNavbarTransparent(scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const currentStatement = statements[statementIndex];
    let typingSpeed = isDeleting ? 50 : 150;

    if (!isDeleting && displayedText === currentStatement) {
      setTimeout(() => setIsDeleting(true), 2000);
    } else if (isDeleting && displayedText === "") {
      setIsDeleting(false);
      setStatementIndex((prevIndex) => (prevIndex + 1) % statements.length);
    } else {
      const updateText = () => {
        setDisplayedText((prevText) =>
          isDeleting
            ? currentStatement.substring(0, prevText.length - 1)
            : currentStatement.substring(0, prevText.length + 1)
        );
      };
      setTimeout(updateText, typingSpeed);
    }
  }, [displayedText, isDeleting, statementIndex]);

  return (
    <>
      <nav
        className={`navbar ${navbarTransparent ? "transparent" : ""} ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"
          }`}
      >
        <div className="navbar-logo-container">
          <Link to="/ardraGPTPage">
            <img src={logo} alt="Ardra AI Logo" className="navbar-logo" />
          </Link>
          <span className="navbar-text">ardraGPT</span>
        </div>

        <div className="add_sentence">
          <p>
            {displayedText}
            <span className="cursor"></span>
          </p>
        </div>
        <Tooltip id="my-tooltip" style={{
          border: "1px solid red", zIndex: "100", backgroundColor: "#F8F8F8",
          color: "black",
          padding: "8px",
          borderRadius: "4px",
          width: "200px", // Set a fixed width to force wrapping
          wordWrap: "break-word", // Ensure long words break
          textAlign: "center",
        }} />
        <button className="navbar-demo-link desktop-only" onClick={openModal} data-tooltip-id="my-tooltip"
          data-tooltip-content="Transform Your Vision with Expert AI Guidance!"
          data-tooltip-place="bottom"
          data-tooltip-position-strategy="fixed">
          Talk to a Generative AI Expert
        </button>

        <button className="navbar-toggle" onClick={toggleDropdown}>
          ☰
        </button>
      </nav>

      {isDropdownOpen && (
        <div className="navbar-dropdown">
          <button className="navbar-demo-link" onClick={openModal}>
            Talk to a Generative AI Expert
          </button>
        </div>
      )}
      ``
      <ModalForm isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Navbar;
