import React from "react";
import "./TermsAndConditions.css"
const TermsAndConditions = () => {
    return (
        <div className="terms-container" style={{ padding: "20px", maxWidth: "800px", margin: "auto", lineHeight: "1.6" }}>
            <h1 className="terms-heading">Terms of Use for ArdraGPT</h1>
            <p className="terms-meta"><strong>Effective Date:</strong> 19 November 2024</p>
            <p className="terms-meta"><strong>Last Updated:</strong> 19 November 2024</p>

            <h2 className="subheading">1. Acceptance of Terms</h2>
            <p className="terms-para">
                By accessing or using the ArdraGPT platform, you agree to be bound by these Terms of Use. If you do not agree, you must not use the platform. These terms form a legally binding agreement between you and Ardra AI.
            </p>

            <h2 className="subheading">2. Use of the Platform</h2>
            <h3 className="subheading2">2.1 Eligibility</h3>
            <p className="terms-para">
                To use ArdraGPT, you must:
            </p>
            <ul className="terms-point">
                <li className="terms-subpoint">Be at least 18 years of age or the legal age in your jurisdiction.</li>
                <li className="terms-subpoint">Have the legal authority to agree to these terms on behalf of yourself or an organization.</li>
            </ul>

            <h3 className="subheading2">2.2 License to Use</h3>
            <p className="terms-para">
                Ardra AI grants you a non-exclusive, non-transferable, revocable license to access and use the platform for lawful purposes.
            </p>

            <h3 className="subheading2">2.3 Restrictions</h3>
            <ul className="terms-point">
                <li className="terms-subpoint">Reverse-engineer or attempt to extract the source code of the platform.</li>
                <li className="terms-subpoint">Use the platform to engage in illegal or unauthorized activities.</li>
                <li className="terms-subpoint">Exploit the platform for commercial purposes without prior approval.</li>
            </ul>

            <h2 className="subheading">3. User Responsibilities</h2>
            <h3 className="subheading2">3.1 Account Security</h3>
            <p className="terms-para">
                You are responsible for maintaining the confidentiality of your account credentials and for any activity conducted under your account.
            </p>

            <h3 className="subheading2">3.2 Compliance with Laws</h3>
            <p className="terms-para">
                You agree to comply with all applicable laws and regulations when using ArdraGPT.
            </p>

            <h3 className="subheading2">3.3 Accurate Information</h3>
            <p className="terms-para">
                You must provide accurate and up-to-date information during registration and when using the platform.
            </p>

            <h2 className="subheading">4. Data and Privacy</h2>
            <p className="terms-para">
                ArdraGPT processes data as outlined in our Privacy Policy. By using the platform, you consent to the collection and use of data as described therein.
            </p>

            <h2 className="subheading">5. Platform Availability and Updates</h2>
            <h3 className="subheading2">5.1 Availability</h3>
            <p className="terms-para">
                ArdraGPT is provided on an “as-is” and “as-available” basis. Ardra AI makes no guarantees regarding uptime or uninterrupted access.
            </p>

            <h3 className="subheading2">5.2 Updates and Changes</h3>
            <p className="terms-para">
                Ardra AI may modify, suspend, or discontinue parts of the platform without prior notice.
            </p>

            <h2 className="subheading">6. Intellectual Property</h2>
            <h3 className="subheading2">6.1 Ownership</h3>
            <p className="terms-para">
                All intellectual property rights in the platform, including its content, features, and functionality, belong to Ardra AI.
            </p>

            <h3 className="subheading2">6.2 Limited Use</h3>
            <p className="terms-para">
                You are granted a limited license to use the platform for its intended purpose. All rights not expressly granted remain with Ardra AI.
            </p>

            <h2 className="subheading">7. Limitation of Liability</h2>
            <p className="terms-para">
                To the maximum extent permitted by law, Ardra AI is not liable for:
            </p>
            <ul className="terms-point">
                <li className="terms-subpoint">Any indirect, incidental, or consequential damages.</li>
                <li className="terms-subpoint">Loss of data, profits, or use arising from the use or inability to use the platform.</li>
            </ul>

            <h2 className="subheading">8. Termination</h2>
            <h3 className="subheading2">8.1 By User</h3>
            <p className="terms-para">
                You may terminate your use of ArdraGPT at any time by deleting your account.
            </p>

            <h3 className="subheading2">8.2 By Ardra AI</h3>
            <p className="terms-para">
                We reserve the right to terminate your access to the platform for violations of these terms or other unlawful activities.
            </p>

            <h2 className="subheading">9. Governing Law</h2>
            <p className="terms-para">
                These terms are governed by the laws of California, USA. Any disputes will be resolved in courts located in San Francisco, California, USA.
            </p>

            <h2 className="subheading">10. Changes to Terms</h2>
            <p className="terms-para">
                Ardra AI reserves the right to modify these Terms of Use at any time. Users will be notified of significant changes via email or platform notifications.
            </p>

            <h2 className="subheading">11. Contact Information</h2>
            <p className="terms-para">
                For questions or concerns about these Terms of Use, contact us at:
            </p>
            <ul className="terms-point">
                <li className="terms-subpoint"><strong>Email:</strong> <a href="mailto:hello@ardra.ai" className="terms-link">hello@ardra.ai</a></li>
                <li className="terms-subpoint"><strong>Address:</strong> Ardra AI Inc, 166 Geary St STE 1500 Suite #278, San Francisco, California 94108, United States</li>
            </ul>
        </div>
    );
};

export default TermsAndConditions;
