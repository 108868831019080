import React from "react";
import "./PravacyPolicy.css"

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container" style={{ padding: "20px", maxWidth: "800px", margin: "auto", lineHeight: "1.6" }}>
            <h1 className="privacy-heading">Privacy Policy for ArdraGPT</h1>
            <p className="privacypara"><strong>Effective Date:</strong> 21 September 2024</p>
            <p className="privacypara"><strong>Last Updated:</strong> 19 November 2024</p>

            <h2 className="subheading">1. Introduction</h2>
            <p className="privacypara">
                This Privacy Policy outlines how ArdraGPT collects, uses, and protects user data. By using ArdraGPT, you agree to the terms outlined here.
            </p>

            <h2 className="subheading">2. Data We Collect</h2>
            <h3 className="subheading2">2.1. Personal Data</h3>
            <ul className="privacypoint">
                <li className="privacysubpoint">Name, email address, and account credentials when registering.</li>
                <li className="privacysubpoint">Location data, if required for energy optimization features.</li>
            </ul>
            <h3 className="subheading2">2.2. Usage Data</h3>
            <ul className="privacypoint">
                <li className="privacysubpoint">Interaction logs and user queries to improve performance.</li>
                <li className="privacysubpoint">Device information such as IP addresses, device types, and operating systems.</li>
            </ul>
            <h3 className="subheading2">2.3. Sensitive Data</h3>
            <ul className="privacypoint">
                <li className="privacysubpoint">Access to microphones (only for voice-based queries), with explicit consent.</li>
            </ul>

            <h2 className="subheading">3. How We Use Data</h2>
            <ul className="privacypoint">
                <li className="privacysubpoint"><strong>Service Delivery:</strong> To provide accurate energy insights and analytics.</li>
                <li className="privacysubpoint"><strong>Platform Improvement:</strong> Analyze user interactions for feature optimization.</li>
                <li className="privacysubpoint"><strong>Compliance:</strong> Ensure adherence to regulatory and operational requirements.</li>
            </ul>

            <h2 className="subheading">4. Data Sharing and Permissions</h2>
            <h3 className="subheading2">4.1. Third-Party Services</h3>
            <p className="privacypara">
                Data is shared with trusted third-party platforms only for analytics and reporting. Third parties comply with Google’s data-sharing standards.
            </p>
            <h3 className="subheading2">4.2. User Consent</h3>
            <p className="privacypara">
                All permissions (e.g., microphone access) are sought through clear, in-app dialogs before collection. Users can manage permissions within their device settings.
            </p>

            <h2 className="subheading">5. Data Protection</h2>
            <ul className="privacypoint">
                <li className="privacysubpoint"><strong>Encryption:</strong> All user data is encrypted in transit (SSL/TLS) and at rest (AES-256).</li>
                <li className="privacysubpoint"><strong>Access Controls:</strong> Multi-factor authentication and role-based access limit data access.</li>
                <li className="privacysubpoint"><strong>Real-Time Threat Monitoring:</strong> The platform continuously detects and mitigates threats.</li>
            </ul>

            <h2 className="subheading">6. User Rights</h2>
            <ul className="privacypoint">
                <li className="privacysubpoint"><strong>Data Access:</strong> Users can review their personal data through account settings.</li>
                <li className="privacysubpoint"><strong>Data Deletion:</strong> Requests for account and data deletion can be made through support channels.</li>
                <li className="privacysubpoint"><strong>Consent Withdrawal:</strong> Users may revoke permissions at any time via the app or system settings.</li>
            </ul>

            <h2 className="subheading">7. Compliance and Updates</h2>
            <ul className="privacypoint">
                <li className="privacysubpoint">Adheres to GDPR, CCPA, and other applicable laws.</li>
                <li className="privacysubpoint">Regular updates will reflect changes in app functionality or data handling practices.</li>
            </ul>

            <h2 className="subheading">8. Contact Us</h2>
            <p className="privacypara">
                For inquiries or concerns, reach out at:
            </p>
            <ul className="privacypoint">
                <li className="privacysubpoint"><strong>Email:</strong> <a href="mailto:hello@ardra.ai" className="privacylink">hello@ardra.ai</a></li>
                <li className="privacysubpoint"><strong>Address:</strong> Ardra AI Inc, 166 Geary St STE 1500 Suite #278, San Francisco, California 94108, United States</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;
