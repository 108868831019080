import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import demoVideo from '../Assist/demoVideo.mp4';

const FeatureSection = () => {
  const [openFeature, setOpenFeature] = useState(null);

  const features = [
    {
      title: 'Advanced Data Insights',
      // content: 'ArdraGPT leverages AI to provide actionable insights from your energy data, allowing for more informed decision-making and improved energy efficiency.'
      content: 'ArdraGPT leverages AI to provide actionable insights from your data, enabling more informed decision-making and enhanced efficiency.'
    },
    {
      title: 'Seamless Integration',
      content: 'Easily integrate ArdraGPT with various data sources like MySQL databases, APIs, Power BI, and IoT devices to create a unified data management system.'
      // content: 'Easily integrate ArdraGPT with various data sources like MySQL databases, APIs, Power BI, and IoT devices to create a unified energy management system.'
    },
    {
      title: 'Customizable Reporting',
      content: 'Generate customizable reports tailored to your specific needs, with options to include diverse data visualizations and metrics.'
      // content: 'Generate customizable reports tailored to your specific needs, with options to include various data visualizations and metrics.'
    },
    {
      title: 'Real-Time Monitoring',
      // content: 'Monitor your energy usage and performance in real-time with live data updates and alerts, ensuring that you stay informed of any anomalies or issues.'
      content: 'Monitor your efficiency and performance in real-time with live data updates and alerts, ensuring that you stay informed of any anomalies or issues.'
    }
  ];

  return (
    <div id='features-section'  className=" py-20 flex justify-center items-center">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-8">
        
        {/* Left Side: Video*/}
        <div className="md:w-1/2 p-4 rounded-lg shadow-lg bg-white">
          <video
          src={demoVideo}
            className="rounded-lg shadow-lg w-full h-auto"
            autoPlay
            loop
            muted
            controls
          >
           
          </video>
        </div>

        {/* Right Side: Collapsible Content */}
        <div className="md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Explore Features of ArdraGPT</h2>
          <div className="space-y-4">
            {features.map((feature, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  className="w-full flex justify-between items-center py-4 text-lg font-medium text-gray-700 hover:text-custom-blue focus:outline-none"
                  onClick={() => setOpenFeature(openFeature === index ? null : index)}
                >
                  {feature.title}
                  <span className={`transform transition-transform ${openFeature === index ? 'rotate-180' : 'rotate-0'}`}>
                    ▼
                  </span>
                </button>
                <Collapse isOpened={openFeature === index}>
                  <div className="p-4 text-gray-600">
                    {feature.content}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default FeatureSection;
