import React, { useState } from "react";
import axios from "axios";
import "./DbConnectionDialogBox.css";
import { useUser } from "../context/UserContext";

const DbConnectionDialogBox = ({ onClose, setTablesData, dbType, setSelectedTable = () => { } }) => {
    // const [dbType, setDbType] = useState("SQL");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [host, setHost] = useState("");
    const [database, setDatabase] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [tables, setTables] = useState([]);

    const [changeTable, setChangeTable] = useState("");

    console.log(dbType)
    dbType = dbType?.toUpperCase();




    const { token, authToken } = useUser();
    const effectiveToken = token || authToken;
    const handleTestConnection = async () => {
        const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8050";
        setIsLoading(true);
        setMessage("");
        try {
            const response = await axios.post(`${API_URL}/list-tables `, {
                dbType,
                username,
                password,
                host,
                database,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${effectiveToken}`,
                },
            });
            console.log(response.data);

            const tablesWithNumbers = response.data.tables.map((table, index) => ({
                number: index + 1,
                name: table,
            }));

            setTablesData(response.data);
            setTables(tablesWithNumbers);

            setMessage(`Connection successful!`);
            setShowNewDialog(true);
        } catch (error) {
            setMessage(`Connection failed: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsLoading(false);
        }
    };


    const handleCancel = () => {

        setUsername("");
        setPassword("");
        setHost("");
        setDatabase("");
        setMessage("");
        onClose()
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await handleTestConnection(); // Call Test Connection logic
    };

    const closeNewDialog = () => {
        setShowNewDialog(false);
    };

    const SubmitTable = async () => {
        setSelectedTable({
            dbType,
            username,
            password,
            host,
            database,
            table_name: changeTable
        });
        onClose();
    };

    return (
        <>
            <div className="modal-overlay_oracle">
                <div className="modal-content_oracle">
                    <div className="dialog-box">
                        <div className="close-button_oracle">
                            <h2>Fill {dbType} Data</h2>
                            <button aria-label="Close" onClick={handleCancel}>
                                ✖
                            </button>
                        </div>

                        <form className="form" onSubmit={handleSubmit}>
                            {/* <label>
                            Database Type:
                            <select value={dbType} onChange={(e) => setDbType(e.target.value)} required>
                                <option value="SQL">SQL</option>
                                <option value="OracleDB">OracleDB</option>
                            </select>
                        </label> */}
                            <label>
                                Username:
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter your username" required
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password" required
                                />
                            </label>
                            <label>
                                Host:
                                <input
                                    type="text"
                                    value={host}
                                    onChange={(e) => setHost(e.target.value)}
                                    placeholder="e.g., localhost or domain.com" required
                                />
                            </label>
                            <label>
                                Database:
                                <input
                                    type="text"
                                    value={database}
                                    onChange={(e) => setDatabase(e.target.value)}
                                    placeholder="Enter your database name" required
                                />
                            </label>
                            <div className="actions">
                                <button type="submit" disabled={isLoading}> {isLoading ? "Testing..." : "Submit"}</button>

                                <button type="button" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                        {message && <p className="message">{message}</p>}
                    </div>
                </div>
            </div>

            {/* New Dialog Box */}
            {
                showNewDialog && (
                    <div className="modal-overlay_oracle">
                        <div className="modal-content_oracle">
                            <div className="dialog-box">
                                <h2>List of Tables</h2>
                                <div className="table-container2">
                                    <table className="styled-table">
                                        <thead>
                                            <tr>
                                                <th>Select</th>
                                                <th>Table Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tables.map((table, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="radio"
                                                            name="selectedTable"
                                                            value={table.name}
                                                            onChange={() => setChangeTable(table.name)}
                                                        />
                                                    </td>
                                                    <td>{table.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="actions">
                                    <button onClick={SubmitTable} className="close-btn">Submit</button>
                                    <button onClick={closeNewDialog} className="close-btn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>



                )
            }
        </>
    );
};

export default DbConnectionDialogBox;
